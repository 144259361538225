import { CURRENT_ENVIRONMENT } from '@environment/variables';
import { DatadogConfig } from '@shared/entities/common/models/datadog';

export const WALLET_CONNECT_ZENKIPAY_PROJECT_ID =
  '2d2b5ea399f13cd8dc851d484926a045';
export const DATADOG_CONFIG: DatadogConfig = {
  applicationId: '993543a4-3110-4a34-aa1f-4c3a47e311b0',
  clientToken: 'pub95dbd5963544f240d60764b86de49f81',
  service: `modal-zenkipay-${CURRENT_ENVIRONMENT}`,
  sessionReplaySampleRate: 20,
};
export const BINANCE_PROD_PAYMENT = true;
